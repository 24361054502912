import '../styles/_styles.scss';

"use strict";

function init() {
    initNavigation();
    initLeafletMap();
    initContactForm();
    if (screen.width > 400) {
        initScrollTop();
    }
}

function initNavigation() {
    $('.m-navigation__item').click(function () {
        var titleToScrollTo = $(this).attr("data-title");

        $('html, body').animate({
            scrollTop: $("#" + titleToScrollTo).offset().top - 20
        }, 1000);
    });
}

function initLeafletMap() {
    var zoomfactor = 14;

    if (screen.width < 400) {
        zoomfactor = 12;
    }

    var mymap = L.map('mapid', { scrollWheelZoom: false }).setView([50.947330, 3.092190], zoomfactor);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1Ijoic25vZXRjaCIsImEiOiJja2NtZjBtNXQwMHBiMnJsZmpnNmJ0bGJsIn0.V5gjT2g6DOHDQ8dcTVT4jg'
    }).addTo(mymap);

    var werkplaats = L.marker([50.947330, 3.092190]).addTo(mymap);
    // var maatschappelijkeZetel = L.marker([50.930250, 3.122150]).addTo(mymap);

    werkplaats.bindPopup("<b>Werkplaats</b><br>Ovenhoek 13<br> 8800 Roeselare").openPopup();
    // maatschappelijkeZetel.bindPopup("<b>Maatschappelijke zetel</b><br>Meenseheirweg 237<br> 8800 Roeselare").openPopup();

    $('#werkplaats_open-popup').click(function () {
        werkplaats.openPopup();
    });

    // $('#maatschappelijkeZetel').click(function () {
    //     maatschappelijkeZetel.openPopup();
    // });
}

function initContactForm() {
    $("#submit-contact-form").click(function (e) {
        e.preventDefault();

        // Disable submit
        $(this).prop('disabled', true);

        var requiredFields = ['firstname', 'lastname', 'email', 'message'];

        var validForm = true;

        for (let index = 0; index < requiredFields.length; index++) {
            const field = requiredFields[index];

            if ($('#' + field).val().length > 0) {
                $('#' + field).closest('.m-form-container').removeClass('error');
                $('#' + field).closest('.m-form-container').addClass('valid');
            } else {
                $('#' + field).closest('.m-form-container').removeClass('valid');
                $('#' + field).closest('.m-form-container').addClass('error');

                validForm = false;
            }
        }

        if (validateEmail($("#email").val())) {
            $('#email').closest('.m-form-container').removeClass('error');
            $('#email').closest('.m-form-container').addClass('valid');
        } else {
            $('#email').closest('.m-form-container').removeClass('valid');
            $('#email').closest('.m-form-container').addClass('error');

            validForm = false;
        }

        if ($("#privacy").is(":checked")) {
            $("#privacy").closest('.checkbox-container').removeClass('error');
        } else {
            $("#privacy").closest('.checkbox-container').addClass('error');
            validForm = false;
        }

        if (validForm) {
            var firstname = $("#firstname").val();
            var lastname = $("#lastname").val();
            var phone = $("#phone").val() !== "" ? $("#phone").val() : "n.v.t";
            var email = $("#email").val();
            var message = $("#message").val();

            var body = "Onderstaand bericht werd automatisch gegenereerd door de website.%0D%0A%0D%0A" +
                    "voornaam: " + firstname + "%0D%0A" +
                    "achternaam: " + lastname + "%0D%0A" +
                    "telefoonnummer: " + phone + "%0D%0A" +
                    "email: " + email + "%0D%0A%0D%0A" +
                    "bericht: %0D%0A" + message + "%0D%0A%0D%0A";

            window.open('mailto:info@pdvdeuren.be?subject=PDV-deuren website&body=' + body);

            // This does not work on localhost
            // Email.send({
            //     SecureToken: "f36fdfd0-057e-4351-b1ee-a22855761260",
            //     // Host: "smtp.gmail.com",
            //     // Username: "pdv.deuren.website@gmail.com",
            //     // Password: "D6S![xC$!!:%4[zP",
            //     // info@pdvdeuren.be
            //     // Less secure app access moet op ON
            //     To: 'tyberghein-jens@hotmail.com',
            //     From: "pdv.deuren.website@gmail.com",
            //     Subject: "PDV-deuren website",
            //     Body:
            //         "Onderstaand bericht werd automatisch gegenereerd door de website.<br><br>" +
            //         "voornaam: <b>" + firstname + "</b><br>" +
            //         "achternaam: <b>" + lastname + "</b><br>" +
            //         "telefoonnummer: <b>" + phone + "</b><br>" +
            //         "email: <b>" + email + "</b><br><br>" +
            //         "bericht: <br>" + message + "<br><br>",
            // }).then(
            //     (message) => {
            //         console.log(message);
            //         $(".m-contact__form").fadeOut();
            //         setTimeout(function () { $(".m-contact__form--success").fadeIn(); }, 500);
            //     }
            // );
        }

        // Disable submit
        $(this).prop('disabled', false);

    });
}

function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function initScrollTop() {
    //Check to see if the window is top if not then display button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.a-scroll-top').fadeIn();
        } else {
            $('.a-scroll-top').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.a-scroll-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });
}

(() => {
    init();
})();